<template>
  <b-modal
    id="library-image-picker-modal"
    size="lg"
    title="انتخاب عکس از کتابخانه"
    ok-title="انتخاب"
    cancel-title="بستن"
    centered
    @ok="insertImage"
  >
    <b-overlay
        :show="isLoading"
        rounded="sm"
    >
      <b-container fluid>

        <b-card>
          <div class="mb-3 w-100 d-flex align-items-center">
            <h1>+ افزودن عکس</h1>
          </div>
          <b-row>
            <b-col cols="12">
              <div
                  class="w-100 dropzone  flex d-flex flex-column align-items-center justify-content-center"
                  @click="openFileBrowser"
              >
                <b-button variant="primary" type="button">جهت آپلود فایل کلیک کنید</b-button>
                <input
                    @change="convertImageFileToBase64"
                    accept="image/*"
                    type="file"
                    style="display: none"
                    ref="filePicker"
                />
              </div>
            </b-col>
          </b-row>
        </b-card>

        <!--      <b-col-->
        <!--          cols="4"-->
        <!--          class="position-relative h-auto cursor-pointer"-->
        <!--          @click="selectImage(image)"-->
        <!--      >-->
        <!--        <div-->
        <!--            class="overlay"-->
        <!--            v-if="selectedImage && selectedImage.mediaId == image.mediaId"-->
        <!--        ></div>-->
        <!--        <img :src="handleImageUrl(image)" alt="" class="w-100 h-auto" />-->
        <!--        <feather-icon-->
        <!--            v-if="selectedImage && selectedImage.mediaId == image.mediaId"-->
        <!--            icon="CheckIcon"-->
        <!--            size="30"-->
        <!--            class="custom-selected-icon text-success"-->
        <!--        />-->
        <!--      </b-col>-->

        <b-form-row v-if="libraryList">
          <b-col
              cols="2"
              v-for="image in libraryList"
              :key="image.mediaId"
              class="position-relative h-auto cursor-pointer"
              @click="selectImage(image)"
          >
            <div
                class="overlay"
                v-if="selectedImage && selectedImage.mediaId == image.mediaId"
            ></div>
            <img :src="handleImageUrl(image)" alt="" class="w-100 h-auto" />
            <feather-icon
                v-if="selectedImage && selectedImage.mediaId == image.mediaId"
                icon="CheckIcon"
                size="30"
                class="custom-selected-icon text-success"
            />
          </b-col>
        </b-form-row>
      </b-container>
    </b-overlay>
  </b-modal>
</template>

<script>
import Helper from "@/libs/Helper";
import {MediaGetAllRequest , MediaCreateFileRequest} from "@/libs/Api/Media";
import {
  BModal,
  BContainer,
  BFormRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
    BRow,
    BCard,
    BOverlay
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    BModal,
    BContainer,
    BFormRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCard,
    BOverlay,
    ToastificationContent
  },
  emits: ["insertImage"],
  mounted() {
    this.getAllLibraryFiles();
  },
  data() {
    return {
      isLoading: false,
      libraryList: null,
      count: 0,
      pageNumber: 0,
      totalCount: null,
      selectedImage: null,
      fileDetails: {
        base64: null,
        objectId: 0,
        type: 9,
        priority: null,
      },
    };
  },
  methods: {
    insertImage() {
      this.$emit("insertImage", this.handleImageUrl(this.selectedImage));
      this.selectedImage = null;
    },
    selectImage(image) {
      this.selectedImage = image;
    },
    openFileBrowser() {
      this.$refs.filePicker.click();
    },
    async convertImageFileToBase64(event) {
      this.fileDetails.base64 = await Helper.readAsDataURL(
          event.target.files[0]
      );
      this.sendCreateFileRequest();
    },
    async sendCreateFileRequest() {
      try {
        this.isLoading = true;
        let _this = this;
        let createFile = new MediaCreateFileRequest(_this);
        createFile.setParams(_this.fileDetails);
        await createFile.fetch((response) => {
          if (response.data.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `فایل آپلود شد.`,
              },
            });
            _this.fileDetails = {
              base64: null,
              objectId: 0,
              type: 9, // 10 is for library
              priority: null,
            };
            _this.getAllLibraryFiles();
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleImageUrl(image) {
      return `${Helper.baseUrl}Media/Gallery/Libraries/${image.pictureUrl}`;
    },
    async getAllLibraryFiles() {
      try {
        this.isLoading = true;
        let _this = this;
        let getLibraryFiles = new MediaGetAllRequest(_this);
        getLibraryFiles.setParams({
          count: _this.count,
          pageNumber: _this.pageNumber,
          type:9,
        });
        await getLibraryFiles.fetch((response) => {
          if (response.isSuccess) {
            _this.libraryList = response.data.medias;
            _this.totalCount = response.data.mediasCount;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};

</script>
<style scoped>
.custom-selected-icon {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
.overlay {
  background-color: rgba(189, 189, 189, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
</style>
