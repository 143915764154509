<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <b-card class="blog-edit-wrapper">

      <h1>جزئیات</h1>
      <!--    Editor Modal  -->
      <b-modal
          id="modal-Editor"
          centered
          ok-only
          ok-title="ثبت"
          title="افزودن کد html"
          @ok.preventDefault()="customButtonClick"
      >
        <b-row>
          <span class="mx-1 mb-1">کد HTML خود را در اینجا قرار دهید</span>
          <b-col cols="12">
            <textarea v-model="rawHTML" class="form-control"></textarea>
          </b-col>

        </b-row>
      </b-modal>

      <!--  Add category   -->
      <b-modal
          id="modal-Add"
          centered
          ok-only
          ok-title="ثبت"
          title="افزودن دسته بندی جدید"
          @ok.preventDefault()="createBlogCategory"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <label>نام دسته بندی</label>
              <b-form-input
                  v-model="newCategory.name"
                  placeholder="نام دسته بندی"
                  type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <label>نوع</label>
              <v-select
                  v-model="newCategory.type"
                  :clearable="false"
                  :options="categoryTypeOptions"
                  :reduce="val => val.value"
                  input-id="type"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="newCategory.type === 2" cols="12">
            <b-form-group>
              <label>الگو</label>
              <v-select
                  v-model="newCategory.schema"
                  :clearable="false"
                  :options="schemaOptions"
                  :reduce="val => val.value"
                  input-id="type"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>

      <!--  Add tag    -->
      <b-modal
          id="modal-Add-tag"
          centered
          ok-only
          ok-title="ثبت"
          title="افزودن برچسب جدید"
          @ok.preventDefault()="createNewTag"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <label>نام برچسب</label>
              <b-form-input
                  v-model="newTag.value"
                  placeholder="نام برچسب"
                  type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>

      <validation-observer
          ref="refFormObserver"
      >

        <!-- form -->
        <b-form
            class="mt-2">
          <b-row>
            <b-col md="6">
              <b-form-group
                  class="mb-2"
                  label="تیتر"
                  label-for="blog-edit-title"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="blog-edit-title"
                      v-model="blog.title"
                      :state="errors.length > 0 ? false:null"
                  />
                  <!--                  <small class="text-danger mt-3">{{ errors[0] }}</small>-->
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  class="mb-2"
                  label="سوتیتر"
                  label-for="blog-edit-subTitle"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="blog-edit-subTitle"
                      v-model="blog.subTitle"
                      :state="errors.length > 0 ? false:null"
                  />
                  <!--                  <small class="text-danger mt-3">{{ errors[0] }}</small>-->
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-row>
                <b-col cols="10">
                  <b-form-group
                      class="mb-2"
                      label="دسته بندی"
                      label-for="category"
                  >
                    <validation-provider
                        #default="{ errors }"
                        rules="required"
                    >
                      <v-select
                          v-if="blogCategories !== null"
                          id="category"
                          v-model="blog.blogCategoryIds"
                          :clearable="false"
                          :multiple="true"
                          :options="blogCategories"
                          :reduce="name => name.blogCategoryId"
                          :state="errors.length > 0 ? false:null"
                          dir="rtl"
                          label="name"
                      />
                      <small class="text-danger mt-3">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12">
              <b-row>
                <b-col cols="10">
                  <b-form-group
                      class="mb-2"
                      label="برچسب جدید"
                      label-for="tagIds"
                  >
                    <b-form-input
                        id="tagIds"
                        v-model="newTag.value"
                        placeholder="برچسب جدید"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-button
                      class="mr-1 mt-2"
                      variant="success"
                      @click="addNewTag"
                  >
                    افزودن برچسب جدید
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-if="blog.tags.length > 0" class="my-2" cols="12">
              <h3 class="mb-1">لیست برچسب ها</h3>
              <div class="d-flex align-items-center gap-2">
                <b-badge v-for="(item,idx) in blog.tags" :key="idx" class="mx-25 p-1 d-flex align-items-center"
                         variant="warning">
                  <feather-icon class="mx-50 text-danger cursor-pointer" icon="XIcon"
                                @click="blog.tags.splice(idx,1)"></feather-icon>
                  <span class="mt-25">
                {{ item.value }}
                </span>
                </b-badge>
              </div>
            </b-col>

            <!-- Field:  publishDate -->
            <b-col
                cols="12"
                md="6"
            >
              <b-form-group
                  label="تاریخ و زمان انتشار"
                  label-for=""
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <input
                      :id="`start${1}`"
                      :state="errors.length > 0 ? false:null"
                      :value="createJalaliDate(blog.publishDate)"
                      class="form-control form-control-lg w-11/12 2xl:h-12 h-10 mx-auto mb-1 text-sm block w-full rounded-full text-center bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder-inputPlaceHolder text-gray-700"
                      placeholder="تاریخ و زمان انتشار"
                      type="text"
                  />
                </validation-provider>
                <custom-date-picker
                    v-model="blog.publishDate"
                    :element="`start${1}`"
                    :min="new Date(Date.now())"
                    auto-submit
                    class=""
                    color="#5c6bc0"
                    display-format="jYYYY/jMM/jDD HH:mm"
                    format="YYYY-MM-DD HH:mm"
                    input-format="YYYY-MM-DD HH:mm"
                    type="datetime"
                ></custom-date-picker>
              </b-form-group>
            </b-col>

            <!-- Field:  status -->
            <b-col cols="6">
              <b-form-group
                  class="mb-2 mt-1"
                  label="وضعیت"
                  label-for="status"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <v-select
                      id="status"
                      v-model="blog.status"
                      :options="statusOptions"

                      :reduce="val => val.value"
                      :state="errors.length > 0 ? false:null"
                      dir="rtl"
                  />
                  <small class="text-danger mt-3">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field:  description -->
            <b-col
                cols="12"
            >
              <b-form-group
                  label="خلاصه"
                  label-for="summary"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-textarea
                      v-model="blog.summary"
                      :state="errors.length > 0 ? false:null"
                  ></b-form-textarea>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  class="mb-0"
                  label="توضیحات"
                  label-for="blog-content"
              >
                <ckEditorMain ref="editorContent" @getEditorContent="setEditorContent"></ckEditorMain>
              </b-form-group>
              <textarea v-if="showHTML" dir="ltr" id="" v-model="rawHTML" class="form-control mb-2 " cols="30"
                        name="textArea"
                        rows="10"></textarea>
            </b-col>
            <b-col
                class="my-2"
                cols="12"
            >
              <div class="border rounded p-2">
                <h4 class="mb-1">
                  پیوست عکس شاخص
                </h4>
                <b-media
                    class="flex-column flex-md-row"
                    no-body
                    vertical-align="center"
                >
                  <b-media-aside>
                    <b-img
                        ref="refPreviewEl"
                        :src="base64ImageSrc"
                        class="rounded mr-2 mb-1 mb-md-0"
                        height="110"
                        width="170"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <div class="d-inline-block">
                      <b-form-file
                          ref="refInputEl"
                          accept=".jpg, .png, .gif"
                          placeholder="انتخاب عکس"
                          @input="makeBase64Pic"
                      />
                    </div>
                  </b-media-body>
                </b-media>
              </div>
            </b-col>
          </b-row>
        </b-form>
        <!--/ form -->
      </validation-observer>
    </b-card>

    <b-card class="blog-edit-wrapper">
      <h2>سئو</h2>

      <b-form class="mt-2">
        <b-row>
          <b-col class="" md="6">
            <b-form-group
                class=""
                label="عنوان"
                label-for="blog-edit-title"
            >
              <b-form-input
                  id="blog-edit-seoTitle"
                  v-model="blog.seoTitle"
              />
            </b-form-group>
            <span :class="{'text-danger':blog.seoTitle.length>60}"
                  class="">تعداد کاراکترهای مجاز <small>{{
                blog.seoTitle.length
              }}</small><small
                class="text-secondary">/</small><small class="text-secondary">60</small></span>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col cols="10">
                <b-form-group
                    class=""
                    label="آدرس"
                    label-for="blog-edit-seourl"
                >

                  <b-form-input
                      id="blog-edit-seourl"
                      ref="myInput"

                      v-model="allCharactersToDash"
                      v-on:focus="$event.target.select()"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button
                    class="mt-2 ml-2"
                    variant="warning"
                    @click="copyText"
                >
                  کپی
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <!-- Field:  description -->
          <b-col class="mt-2" cols="12">
            <b-form-group
                class="mb-2"
                label="توضیحات"
                label-for="blog-content"
            >
              <b-form-textarea

                  v-model="blog.seoDescription"
              ></b-form-textarea>
            </b-form-group>
            <span :class="{'text-danger':blog.seoDescription.length>160}"
                  class="">تعداد کاراکترهای مجاز <small>{{
                blog.seoDescription.length
              }}</small><small
                class="text-secondary">/</small><small class="text-secondary">160</small></span>
          </b-col>

          <b-card class="mt-1 w-100">

            <h2>نحوه نمایش در گوگل</h2>

            <div class="col-md-12 my-3">
              <!--- serp Desktop --->
              <div class="serp-preview d-lg-flex p-3 d-none flex-column align-items-end">
                <div class="serp-title">
                  <h4 style="color: rgb(26, 13, 171)!important">
                    {{ blog.seoTitle }}
                  </h4>
                </div>
                <div class="serp-url">
                  <h5 class="d-flex align-items-center flex-wrap" style="color: rgb(0, 102, 33)!important">
                    <span style="overflow-wrap: anywhere">{{ blog.seourl.replaceAll(' ', '-') }}/</span>
                    <span>
                             {{ 'https://enzamode.ir/magazine/{id}/' }}
                        <span class="serp-arrow"></span>
                      </span>

                  </h5>
                </div>
                <div class="serp-description">
                  <h6 style="overflow-wrap: anywhere">
                    {{ blog.seoDescription }}
                  </h6>
                </div>
              </div>
              <!--- serp Mobile --->
              <div class="serp-preview d-lg-none  p-3 d-flex flex-column align-items-end">
                <div class="serp-title">
                  <h6>
                    {{ blog.seoTitle }}
                  </h6>
                </div>
                <div class="serp-url d-flex align-items-center flex-wrap">

                  <small>{{ blog.seourl.replaceAll(' ', '-') }}/</small>
                  <small>
                    {{ 'https://enzamode.ir/magazine' }}
                    <small class="serp-arrow"></small>
                  </small>
                </div>
                <div class="serp-description">
                  <small>
                    {{ blog.seoDescription }}
                  </small>
                </div>
              </div>
            </div>
          </b-card>

          <b-col
              class="mt-2"
              cols="12"
          >
            <b-button
                class="mr-1"
                variant="primary"
                @click="validationForm"
            >
              ثبت بلاگ
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <!-- Image Library Modal  -->
    <library-image-picker @insertImage="insertImage"></library-image-picker>

  </b-overlay>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTextarea,
  BBadge,
  BOverlay, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  BlogCategoryCreateRequest,
  BlogCategoryGetAllRequest,
  TagCreateRequest,
  TagGetAllRequest
} from "@/libs/Api/Blog";
import Ripple from "vue-ripple-directive";
import {BlogCreateBlogRequest} from "@/libs/Api/Blog";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from '@validations'
import LibraryImagePicker from "@/views/pages/blog/LibraryImagePicker";
import ckEditorMain from "@/views/components/ckEditorMain";

export default {
  name: "BlogCreate",
  title: "افزودن بلاگ",
  data() {
    return {
      required,
      blogCategories: null,
      base64ImageSrc: null,
      tags: null,
      showOverlay: false,
      snowOption: {
        theme: 'snow',
        modules: {
          toolbar: {
            container: "#toolbar",
            handlers: {
              image: this.imageMethod,
            },
          },
        },
      },
      rawHTML: '',
      blog: {
        isDeleted: false,
        blogId: 0,
        description: ``,
        publishDate: '',
        summary: "",
        title: "",
        staus: 1,
        subTitle: '',
        blogCategoryIds: [],
        fileData: "",
        tagIds: [],
        createDate: this.currentDate,
        seoTitle: '',
        seoDescription: '',
        seourl: '',
        tags: [],
      },
      newCategory: {
        isDeleted: false,
        blogCategoryId: 0,
        name: '',
        type: 1,
        schema: null
      },
      categoryTypeOptions: [
        {label: 'بلاگ', value: 1},
        {label: 'مجله', value: 2},
      ],
      schemaOptions: [
        {label: '1', value: 1},
        {label: '2', value: 2},
        {label: '3', value: 3},
      ],
      statusOptions: [
        {label: 'منتشر شده', value: 1},
        {label: 'حذف شده', value: 2},
        {label: 'پیش نویس', value: 3},
      ],
      newTag: {
        isDeleted: false,
        tagId: 0,
        type: 4,
        value: '',
        objectId: 0,
        createDate: new Date(Date.now())
      },
      showHTML: false,
    }
  },
  computed: {
    currentDate() {
      let today = new Date();
      let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date + ' ' + time;
    },
    allCharactersToDash: {
      get: function () {
        return this.blog.seourl;
      },
      set: function (newValue) {
        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue) {
          this.blog.seourl = newValue
          // Remove all characters that are NOT number
          this.blog.seourl = this.blog.seourl.replace(/[+*!@#$%^&*()_;:~`»«,×='"|<>/?{}\-/\.]/g, "-");
          this.blog.seourl = this.blog.seourl.replaceAll(' ', '-')
        } else if (!newValue || this.blog.seourl === "") {
          this.blog.seourl = null;
        }
      },
    },
  },
  async created() {
    await this.getBlogCategories();
    // await this.getBlogTags(0, 0);
  },
  methods: {
    setEditorContent(content) {
      this.blog.description = content
    },
    imageMethod() {
      this.$bvModal.show("library-image-picker-modal");
    },
    insertImage(imageUrl) {
      this.$refs.quillEditor.quill.insertEmbed(
          this.$refs.quillEditor.quill.getSelection()?.index
              ? this.$refs.quillEditor.quill.getSelection().index
              : 1,
          "image",
          imageUrl
      );
    },
    validationForm() {
      let _this = this;
      _this.$refs.refFormObserver.validate().then(success => {
        console.log(success)
        if (success) {
          // eslint-disable-next-line
          _this.createBlog();
        }
      })
    },
    async createBlogCategory() {
      let _this = this;
      _this.showOverlay = true;

      let blogCategoryCreateRequest = new BlogCategoryCreateRequest(_this);
      blogCategoryCreateRequest.setParams(_this.newCategory);
      await blogCategoryCreateRequest.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `دسته بندی جدید اضافه شد.`,
          },
        })
        _this.getBlogCategories();
        _this.showOverlay = false;
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CheckIcon',
            variant: 'danger',
            text: `دسته بندی با این نام موجود است.`,
          },
        })
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async createNewTag() {
      let _this = this;

      let tagCreateRequest = new TagCreateRequest(_this);
      tagCreateRequest.setParams(_this.newTag);
      await tagCreateRequest.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: ` برچسب اضافه شد.`,
          },
        })
        _this.getBlogTags(0, 0);
      }, function (error) {
        window.location.reload();
        console.log(error)
      })
    },
    async getBlogCategories() {
      let _this = this;
      _this.showOverlay = true;

      let blogCategoryGetAllRequest = new BlogCategoryGetAllRequest(_this);
      await blogCategoryGetAllRequest.fetch(function (content) {
        _this.blogCategories = content.blogCategories;
        _this.showOverlay = false;
      }, function (content) {
        _this.showOverlay = false;

      });
    },
    async getBlogTags(pageNumber, count) {
      let _this = this;
      _this.showOverlay = true;

      let data = {
        pageNumber: pageNumber,
        count: count
      }

      let tagGetAllRequest = new TagGetAllRequest(_this);
      tagGetAllRequest.setParams(data);
      await tagGetAllRequest.fetch(function (content) {
        _this.tags = content.tags;
        _this.showOverlay = false;
        // _this.totalCount = content.tagsCount;
      }, function (content) {
        _this.showOverlay = false;

      });
    },
    async copyText() {
      await navigator.clipboard.writeText(this.blog.seourl)
      this.$toast({
        component: ToastificationContent,
        position: 'bottom-center',
        props: {
          title: `آدرس بلاگ کپی شد.`,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    async createBlog() {
      this.$refs.editorContent.emitDataToParent()
      let _this = this;
      _this.showOverlay = true;

      let blogCreateRequest = new BlogCreateBlogRequest(_this);
      blogCreateRequest.setParams(_this.blog);
      await blogCreateRequest.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `بلاگ ساخته شد.`,
          },
        })
        _this.showOverlay = false;
        window.location.reload()
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    customButtonClick() {
      this.showHTML = !this.showHTML

      const editor = document.querySelector('.ql-editor')
      if (editor.classList.contains('d-none')) {
        this.blog.description = this.rawHTML
        editor.classList.remove('d-none')

      } else {
        this.rawHTML = this.blog.description
        editor.classList.add('d-none')
      }
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        }
        fr.readAsDataURL(file);
      });
    },
    async makeBase64Pic() {
      const _this = this;
      let file = _this.$refs.refInputEl.files[0]
      _this.base64ImageSrc = URL.createObjectURL(file)
      const result = await _this.readAsDataURL(file);
      _this.blog.fileData = result;
    },
    createJalaliDate(param) {
      if (param !== '') {

        return param.slice(11, 16) + ' - ' + new Date(param.slice(0, 10)).toLocaleDateString("fa-IR",);
      }
    },
    addNewTag() {
      this.blog.tags.push(this.newTag);
      this.newTag = {
        value: "",
        type: 4,
        objectId: 0,
        createDate: new Date(Date.now())
      }
    },
  },
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BOverlay,
    vSelect,
    ckEditorMain,
    BBadge,
    LibraryImagePicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
}
</script>

<style>
.serp-arrow {
  border-bottom-color: rgb(0, 102, 33);
  border-bottom-style: solid;
  border-bottom-width: 0px;
  border-left-color: rgba(0, 0, 0, 0);
  border-left-style: solid;
  border-left-width: 4px;
  border-right-color: rgba(0, 0, 0, 0);
  border-right-style: solid;
  border-right-width: 4px;
  border-top-color: rgb(0, 102, 33);
  border-top-style: solid;
  border-top-width: 5px;
  color: rgb(128, 128, 128);
  cursor: default;
  font-family: arial, sans-serif;
  font-size: 11px;
  font-weight: bold;
  height: 0px;
  position: absolute;
  line-height: 27px;
  margin-left: 3px;
  margin-top: 6px;
  text-align: center;
  user-select: none;
  visibility: visible;
  white-space: nowrap;
  width: 0px;
}

.serp-title {
  color: rgb(26, 13, 171) !important;
  cursor: pointer;
  font-family: arial, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 21.6px;
  text-align: left;
  text-decoration: none;
  visibility: visible;
  white-space: nowrap;
}

.serp-url {
  color: rgb(0, 102, 33) !important;
  font-family: arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  text-align: left;
  visibility: visible;
  white-space: nowrap;
}

.serp-description {
  color: rgb(84, 84, 84) !important;
  font-family: arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 18.2px;
  text-align: left;
  visibility: visible;
  word-wrap: break-word;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
